import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from "./FoodForm.module.scss"
import { Button } from '../../ui/Button/Button';
// import UploadImage from '../../ui/UploadImage/UploadImage';
import { CreateFood } from '../../services/food';

export const FoodForm = () => {
    // const uploadImageRef = useRef()
    // const handleImageSubmit = async () => {
    // const imageUrl = await uploadImageRef.current.handleUpload();
    // if (imageUrl) {
    // return imageUrl
    // }
    // return ""
    // };


    const { register, handleSubmit, formState: { errors }, reset } = useForm({
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        const orderData = {
            name: data.name,
            price: data.price,
            descrition: data.descrition,
            image: data.image,
        }
        CreateFood(orderData);
        setIsSubmitting(false)
        reset()
        window.location.reload()
    };

    return (
        <form className={styles.foodForm_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.foodForm_form_group}>
                <input
                    type="text"
                    id="name"
                    placeholder='Անվանում'
                    {...register('name', { required: 'Անունը պարտադիր է լրացնել' })}
                />
                {errors.name && <p className="error">{errors.name.message}</p>}
            </div>
            <div className={styles.foodForm_form_group}>

                <input
                    type="text"
                    placeholder='Գին'
                    id="price"
                    {...register('price', { required: 'Գինը պարտադիր է լրացնել' })}
                />
                {errors.price && <p className="error">{errors.price.message}</p>}
            </div>

            <div className={styles.foodForm_form_group}>

                <input
                    type="text"
                    placeholder='Նկարի անուն'
                    id="image"
                    {...register('image', { required: 'Նկարի անունը պարտադիր է լրացնել' })}
                />
                {errors.image && <p className="error">{errors.image.message}</p>}
            </div>

            {/* <div className={styles.foodForm_form_group}> */}
            {/* <UploadImage ref={uploadImageRef} /> */}
            {/* </div> */}

            <Button size='small' type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Հաստատում․․․' : "Ավելացնել"}
            </Button>
        </form>
    );
};
