import React, { useEffect } from 'react'
import styles from "./Policy.module.scss"
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'

export const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <div className={styles.policy}>
                <div className="container">
                    <h2>Գաղտնիության քաղաքականություն</h2>
                    <p>Մեր կայքը գնահատում է Ձեր անձնական տվյալների գաղտնիությունը և պարտավորվում է պաշտպանել դրանք:</p>
                    <h4>Տվյալների հավաքագրում</h4>
                    <h5>Մենք հավաքում ենք միայն այն տվյալները, որոնք անհրաժեշտ են՝</h5>
                    <p>Պատվերների մշակման և առաքման համար (օրինակ՝ անուն, հասցե, հեռախոսահամար):</p>
                    <p>Օնլայն վճարումների ապահով կատարման համար:</p>
                    <h4>Տվյալների օգտագործում</h4>
                    <h5>Ձեր տվյալները օգտագործվում են բացառապես հետևյալ նպատակներով՝</h5>
                    <p>Պատվերի մշակում և առաքում: <br />
                        Մեր ծառայությունների բարելավում:<br />
                        Ձեր հետ կապ հաստատելու նպատակով, եթե անհրաժեշտ է:<br />
                    </p>
                    <h4>Տվյալների անվտանգություն</h4>
                    <p>Ձեր տվյալները պաշտպանված են SSL կոդավորմամբ:
                        Մենք երբեք չենք կիսում կամ վաճառում Ձեր անձնական տվյալները երրորդ կողմերին, բացառությամբ՝
                        Առաքման ծառայությունների հետ՝ պատվերի առաքումն ապահովելու համար:
                        Օրենքի պահանջների համաձայն:
                    </p>
                    <h3>Կապ մեզ հետ</h3>
                    <p>Գաղտնիության քաղաքականության վերաբերյալ հարցերի դեպքում, խնդրում ենք կապ հաստատել մեզ հետ զանգահարել՝ <a href="tel:+37455109050">+(374)55-109-050</a>:</p>
                    <br /><br />
                    <p>Այս քաղաքականությունը մշակված է՝ ապահովելու Ձեր տվյալների անվտանգությունը և ծառայությունների որակը:</p>
                </div>
            </div >
            <Footer />
        </>
    )
}
