import React from 'react'
import styles from "./FoodList.module.scss"
import { Button } from '../../ui/Button/Button'
import { deleteFood } from '../../services/food'
import { Loading } from '../Loading/Loading'
import { FoodForm } from '../FoodForm/FoodForm'

export const FoodList = ({ list, change }) => {
    return (
        <div className={styles.food_ul}>
            <FoodForm />
            {list.length ? <> {list.map((food, i) => {
                return (<div className={styles.food_li} key={i}>
                    <img width={60} src={`/images/menu/${food.image}`} alt="" />
                    <span>{food.name} - {food.price}֏</span>
                    <div className={styles.action}>
                        <Button size='small' onClick={() => {
                            change()
                            deleteFood(food._id)
                            window.location.reload()
                        }}>Հեռացնել</Button>
                    </div>
                </div>)
            })}</>
                : <Loading />}</div>
    )
}
