import React, { useState } from 'react'
import styles from "./Main.module.scss"
import { Header } from '../../components/Header/Header'
import { Welcome } from '../../components/Welcome/Welcome'
import { Menu } from '../../components/Menu/Menu'
import { Contact } from '../../components/Contact/Contact'
import { Services } from '../../components/Services/Services'
import { Footer } from '../../components/Footer/Footer'

export const Main = () => {
    const [video, setVideo] = useState(false)

    const [change, setChange] = useState(0)
    const handleChange = () => {
        setChange(change + 1)
    }
    return (
        <div style={video ? { height: "100vh", width: "100vw", overflow: "hidden" } : {}}>
            <Header isChange={change} />
            <Welcome onVideo={setVideo} />
            <Menu change={handleChange} />
            <Services />
            <Contact />
            <Footer />
            {video && <div className={styles.video}>
                <div className={styles.video_close} onClick={() => setVideo(false)}></div>
                <iframe src="https://www.youtube.com/embed/GI7_yQyM_Bg?si=hHUVnYDjo_kjAeFW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>}
        </div>
    )
}
