import React, { useState } from 'react'
import styles from "./Welcome.module.scss"
import { Button } from './../../ui/Button/Button';
import { useNavigate } from 'react-router-dom';

export const Welcome = ({ onVideo }) => {
    const navigate = useNavigate();

    const gotoFragment = (fragment) => {
        setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate("/")
            }
        }, 0);
    };

    return (
        <div className={`${styles.welcome} container`} >
            <div className={styles.welcome_left}>
                <h4>Գեղեցկությունը, համը, բույրը և լավ տրամադրությունը հանդիպել են հենց <span>Կառնուտ շաուրմայում</span></h4>
                <p>Մեր առաքելությունն է ուրախացնել ձեզ համեղ ուտեստներով և ապահովել  <span>արագ ու անվճար առաքում</span></p>
                <div className={styles.actions}>
                    <Button onClick={() => gotoFragment("menu")}>Պատվիրել</Button>
                    <span className={styles.sep}></span>
                    <Button icon="left" variant='link' onClick={() => onVideo(true)}>Դիտել հոլովակը</Button>
                </div>
            </div>
            <div className={styles.welcome_right}>
                <img src="/logo512.png" alt="" />
            </div>
        </div>
    )
}
